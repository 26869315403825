import React from "react"
//import Navbar from "../components/navbar"


export default function Notfound() {

  return (
    <React.Fragment>
    <div>
        404
    </div>
</React.Fragment>  
  )
}